// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  currency : "Rs.",
  
  onesignalappid:'7f7f58ce-f91a-4158-97cb-86febc0d2097',
  firebasesenderid:'631649502200',

  firebaseConfig : {
    apiKey: "AIzaSyB5Gg6zIEFKbqBHrWmU5He-qGuFfUaSpZ4",
    authDomain: "coupon-29a78.firebaseapp.com",
    projectId: "coupon-29a78",
    storageBucket: "coupon-29a78.appspot.com",
    messagingSenderId: "201659355360",
    appId: "1:201659355360:web:92d0932972ca16caabc085",
    measurementId: "G-16SJM416L6"

  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
