import { Component } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { environment } from 'src/environments/environment';
import { OneSignal } from '@ionic-native/onesignal/ngx';

import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private router : Router
  ) {
    this.initializeApp();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.checkfirstlogin();
      this.statusBar.backgroundColorByHexString('#f14726');
      setTimeout(async () => {
        await this.oneSignal.startInit(environment.onesignalappid, environment.firebasesenderid);
        this.oneSignal.getIds().then((data) => {
          console.log('iddddd', data);
          localStorage.setItem('fcm', data.userId);
          
        });
        await this.oneSignal.endInit();
      }, 1000);

      this.platform.backButton.subscribeWithPriority(9999, () => {
        document.addEventListener('backbutton', function (event) {
          navigator['app'].exitApp();
        }, false);
      });


    });

    


     
  }

  checkfirstlogin(){

    if(localStorage.getItem('firstlogin')){
      this.router.navigate(['/home'])
    }else{
      localStorage.setItem('firstlogin','true')
      this.router.navigate(['/onboard'])
    }

      
  }


}
